@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,400;0,500;0,700;0,800;1,400;1,500;1,700;1,800&family=Titillium+Web:wght@900&display=swap");

html {
  scroll-snap-type: y mandatory;
}

body {
  margin: 0;
  font-family: "JetBrains Mono", monospace;
  background-color: rgb(21, 21, 21);
  color: white;
}

a {
  text-decoration: none;
  color: inherit;
}

section {
  scroll-snap-align: start;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
}

h1 {
  font-size: 3rem;
}

h3 {
  font-size: 1.5rem;
  margin: 0;
}

.comments {
  display: flex;
  font-style: italic;
  color: red;
}

.header-section {
  padding: 10vh 20vw;
}

.footer-section {
  padding: 15vh 20vw 10vh 20vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header-section .comments {
  margin-top: 45vh;
  justify-content: right;
}

.footer-section .comments {
  margin-top: 4rem;
}

.accent {
  color: red;
}

.person-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.person-section img {
  display: block;
  width: 500px;
  height: auto;
}

.person-section p {
  padding-left: 2rem;
}

.person-section .media {
  padding-left: 2rem;
  font-size: 2rem;
}

.person-section .media svg {
  padding-right: 0.5rem;
}

.person-section .media svg:hover {
  color: rgb(210, 210, 210);
}
.slide-in-container .person-section > div > * {
  opacity: 0;
  transform: translateX(-30%);
}

.slide-in-container.slide-in .person-section > div > * {
  opacity: 1;
  transform: translateX(0);
}

.person-section > div > h1 {
  transition-property: opacity, transform;
  transition-duration: 0.5s;
}

.person-section > div > p,
.person-section > div > .media {
  transition-property: opacity, transform;
  transition-duration: 0.5s;
  transition-delay: 0.06s;
}

.slide-in-container .person-section > img {
  opacity: 0;
  transform: translateX(30%);
  transition-property: opacity, transform;
  transition-duration: 0.5s;
}

.slide-in-container.slide-in .person-section > img {
  opacity: 1;
  transform: translateX(0);
}
.slide-in-container .header-section,
.slide-in-container .footer-section {
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.5s;
}

.slide-in-container.slide-in .header-section,
.slide-in-container.slide-in .footer-section {
  opacity: 1;
}

@media (max-width: 1024px) {
  .person-section > div {
    margin-left: 3rem;
  }

  .person-section img {
    width: 400px;
  }

  .header-section {
    padding: 10vh 10vw;
  }

  .footer-section {
    padding: 20vh 10vw 15vh 10vw;
  }

  h1 {
    font-size: 2.3rem;
  }

  p {
    font-size: 1rem;
  }
}

@media (max-width: 740px) {
  .person-section img {
    width: 250px;
  }
  h1 {
    font-size: 2rem;
  }
  p {
    font-size: 0.8rem;
  }
}

@media (max-width: 600px) {
  .person-section {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: start;
  }

  .person-section img {
    width: 200px;
    align-self: center;
  }

  .footer-section {
    padding: 25vh 10vw 20vh 10vw;
  }

  h1 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1rem;
  }
  p {
    font-size: 0.8rem;
  }
}
